import { createContext, useReducer, useEffect } from 'react';
import { useFetcher } from '@remix-run/react';

export const VerifyContext = createContext(null);
export const VerifyDispatchContext = createContext(null);

export function VerifyProvider({ children, userId }) {
  const [state, dispatch] = useReducer(verifyReducer, initialState);

  const verifyEmailFetcher = useFetcher({ key: `verifyEmail-${userId}` });
  const verifyEmailCheckFetcher = useFetcher({ key: `verifyEmailCheck-${userId}` });
  const verifyPhoneFetcher = useFetcher({ key: `verifyPhone-${userId}` });
  const verifyPhoneCheckFetcher = useFetcher({ key: `verifyPhoneCheck-${userId}` });

  // If we successfully sent a verification code, show the input form
  useEffect(() => {
    if (verifyEmailFetcher.state === 'idle' && verifyEmailFetcher.data?.success === false) {
      dispatch(setShowEmailCodeForm(false));
      dispatch(setShowEmailValidationForm(true));
    }
  }, [verifyEmailFetcher.data?.success, verifyEmailFetcher.state]);

  useEffect(() => {
    if (verifyPhoneFetcher.state === 'idle' && verifyPhoneFetcher.data?.success === false) {
      dispatch(setShowMobileCodeForm(true));
      dispatch(setShowMobileValidationForm(false));
    }
  }, [verifyPhoneFetcher.data?.success, verifyPhoneFetcher.state]);

  // When verification is complete, hide the forms and show the Verified label.
  useEffect(() => {
    if (verifyEmailCheckFetcher.data?.success) {
      dispatch(setShowEmailCodeForm(false));
    }
  }, [verifyEmailCheckFetcher.data?.success]);

  useEffect(() => {
    if (verifyPhoneCheckFetcher.data?.success) {
      dispatch(setShowMobileCodeForm(false));
    }
  }, [verifyPhoneCheckFetcher.data?.success]);

  return (
    <VerifyContext.Provider
      value={{
        ...state,
        verifyEmailFetcher,
        verifyEmailCheckFetcher,
        verifyPhoneFetcher,
        verifyPhoneCheckFetcher,
      }}
    >
      <VerifyDispatchContext.Provider value={dispatch}>{children}</VerifyDispatchContext.Provider>
    </VerifyContext.Provider>
  );
}

// action types
const REQUEST_EMAIL_CODE = 'REQUEST_EMAIL_CODE';
const REQUEST_MOBILE_CODE = 'REQUEST_MOBILE_CODE';
const SHOW_EMAIL_VALIDATION_FORM = 'SHOW_EMAIL_VALIDATION_FORM';
const SHOW_MOBILE_VALIDATION_FORM = 'SHOW_MOBILE_VALIDATION_FORM';
const SET_EMAIL_VERIFICATION_CODE = 'SET_EMAIL_VERIFICATION_CODE';
const SET_MOBILE_VERIFICATION_CODE = 'SET_MOBILE_VERIFICATION_CODE';
const SHOW_EMAIL_CODE_FORM = 'SHOW_EMAIL_CODE_FORM';
const SHOW_MOBILE_CODE_FORM = 'SHOW_MOBILE_CODE_FORM';

// action dispatchers
export function verifyEmail(email, fetcher) {
  fetcher.submit(
    { email: email },
    {
      method: 'GET',
      action: '/verifyEmail',
    }
  );

  return {
    type: REQUEST_EMAIL_CODE,
  };
}

export function verifyMobile(mobile, fetcher) {
  fetcher.submit(
    { phone: mobile },
    {
      method: 'GET',
      action: '/verifyPhone',
    }
  );

  return {
    type: REQUEST_MOBILE_CODE,
  };
}

export function submitEmailValidationCode(email, code, fetcher) {
  fetcher.submit(
    { email: email, code: code },
    {
      method: 'GET',
      action: '/verifyEmailCheck',
    }
  );
}

export function submitMobileValidationCode(mobile, code, fetcher) {
  fetcher.submit(
    { phone: mobile, code: code },
    {
      method: 'GET',
      action: '/verifyPhoneCheck',
    }
  );
}

export function setShowEmailValidationForm(showEmailValidationForm) {
  return {
    type: SHOW_EMAIL_VALIDATION_FORM,
    showEmailValidationForm,
  };
}

export function setShowEmailCodeForm(showEmailCodeForm) {
  return {
    type: SHOW_EMAIL_CODE_FORM,
    showEmailCodeForm,
  };
}

export function setShowMobileValidationForm(showMobileValidationForm) {
  return {
    type: SHOW_MOBILE_VALIDATION_FORM,
    showMobileValidationForm,
  };
}

export function setShowMobileCodeForm(showForm) {
  return {
    type: SHOW_MOBILE_CODE_FORM,
    showForm,
  };
}

export function setEmailVerificationCode(emailVerificationCode) {
  return {
    type: SET_EMAIL_VERIFICATION_CODE,
    emailVerificationCode,
  };
}

export function setMobileVerificationCode(mobileVerificationCode) {
  return {
    type: SET_MOBILE_VERIFICATION_CODE,
    mobileVerificationCode,
  };
}

function verifyReducer(state, action) {
  switch (action.type) {
    case REQUEST_EMAIL_CODE: {
      return {
        ...state,
        showEmailValidationForm: false,
        showEmailCodeForm: true,
      };
    }
    case REQUEST_MOBILE_CODE: {
      return {
        ...state,
        showMobileValidationForm: false,
        showMobileCodeForm: true,
      };
    }
    case SHOW_EMAIL_VALIDATION_FORM: {
      return { ...state, showEmailValidationForm: action.showEmailValidationForm };
    }
    case SHOW_MOBILE_VALIDATION_FORM: {
      return { ...state, showMobileValidationForm: action.showMobileValidationForm };
    }
    case SET_EMAIL_VERIFICATION_CODE: {
      return { ...state, emailVerificationCode: action.emailVerificationCode };
    }
    case SET_MOBILE_VERIFICATION_CODE: {
      return { ...state, mobileVerificationCode: action.mobileVerificationCode };
    }
    case SHOW_EMAIL_CODE_FORM: {
      return { ...state, showEmailCodeForm: action.showEmailCodeForm };
    }
    case SHOW_MOBILE_CODE_FORM: {
      return { ...state, showMobileCodeForm: action.showMobileCodeForm };
    }
    default: {
      return state;
    }
  }
}

const initialState = {
  emailVerificationCode: '',
  mobileVerificationCode: '',
  showEmailValidationForm: false,
  showMobileValidationForm: false,
  showEmailCodeForm: false,
  showMobileCodeForm: false,
  confirmedEmailValue: null,
  confirmedMobileValue: null,
};
